
/* ========== Custom Styles Token-Controls =========== */

.content .token-controls .edit-add .token-control-btn {
  color: #ffffff !important;
  background-color: #1c6da8;
  border-color: transparent !important;
}

.view-data .view-data-body .view-data-row .wallet-address .view-data-title {
  font-size: 16px !important;
}

@media (max-width: 1024px) {
  .view-data .view-data-body .view-data-row p.wallet-address {
    font-size: 10px;
  }
}


/* ========== Custom Styles Extend Date Modal =========== */
/* 
.extend-modal .extend-modal-title p {
  color: #fff !important;
  font-size: 24px;
  font-weight: 500
}

.extend-modal .modal-body .validator-form {
  padding: 20px 0px 60px;
}

.extend-modal .modal-body .text-field .MuiInputBase-input,
.extend-modal .modal-body .text-field .MuiFormLabel-root {
  color: #fff !important;
}

.extend-modal .modal-body .text-field .MuiInput-underline:before {
  border-bottom: 1px solid #ffffff;
}
.extend-modal .modal-body .text-field .MuiInput-underline:after {
  border-bottom: 1px solid #ffffff;
}

.extend-modal .modal-body .validator-form .extend-btn-div {
  display: flex;
  justify-content: center;
}

.extend-modal .modal-body .validator-form .extend-btn {
  width: 35%;
  color: #ffffff;
  border-radius: 10px;
  background-color: #1c6da8;
} */